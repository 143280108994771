import React from "react"
import Map from "../images/cdp-map.png"

const HomeContacto = () => (
  <div className="w-full mx-auto mt-12 mb-8 bg-gray-900 py-8 lg:py-12 lg:px-12">
    <h5 className="uppercase text-xs text-gray-400  pl-5 lg:pl-0">
      Dónde Estamos
    </h5>
    <h1 className="text-3xl text-gray-200  pl-5 lg:pl-0">Contáctenos</h1>

    <div className="w-full lg:flex mt-8 text-sm">
      <div className="w-full lg:w-1/3  pl-5 lg:pl-0">
        <p className="text-gray-400">
          <b>Email:</b>{" "}
          <a href="mailto:consultas@cdp.co.cr">consultas@cdp.co.cr</a>
        </p>
        <p className="text-gray-400">
          <b>Tel:</b> <a href="tel:+50622806282">(506) 2280-6282</a>
        </p>
        <p className="text-gray-400">
          <b>Apdo Postal:</b> 2430-2050
        </p>
      </div>

      <div className="w-full lg:w-1/3">
        <h5 className="uppercase text-sm text-gray-400 mb-3 mt-12 lg:mt-0  pl-5 lg:pl-0">
          Dirección
        </h5>
        <a href="https://www.waze.com/ul?place=ChIJuR56jXjjoI8RHM_ucfU9kQg&ll=9.93093760%2C-84.06016000&navigate=yes">
          <img src={Map} alt="CDP Mapa - Costa Rica" />
        </a>
      </div>

      <div className="w-full lg:w-1/3">
        <p className="text-sm text-gray-400 ml-5 mt-8">
          San José, Costa Rica. Barrio Los Yoses, calle 41 con avenida 8, San
          Pedro de Montes de Oca. Edificio esquinero.
        </p>
        <p className="text-sm text-gray-400 ml-5 mt-8">
          <a
            href="https://www.waze.com/ul?place=ChIJuR56jXjjoI8RHM_ucfU9kQg&ll=9.93093760%2C-84.06016000&navigate=yes"
            className="underline"
          >
            Abrir en Waze
          </a>
        </p>
      </div>
    </div>
  </div>
)

export default HomeContacto
