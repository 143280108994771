/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import "../css/style.css"

const Layout = ({ children, pageTitle }) => {
  if (!pageTitle) {
    pageTitle = ""
  } else {
    pageTitle = `- ${pageTitle}`
  }
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="nBrFy1fMQMLTn6seRUUSy_MbCCMeNgopmL9_3x3UQec"
        />
        <title>CDP {pageTitle}</title>
      </Helmet>
      <div className="w-full mx-auto">
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
