import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "../images/logo-cdp.png"

const Navigation2 = ({}) => {
  const [isExpanded, toggleExpansion] = useState(false)
  const [isShown, toggleShown] = useState(false)
  const [isLegal, toggleLegal] = useState(false)

  return (
    <nav className="w-full flex items-center justify-between flex-wrap bg-white p-6 ">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <Link to="/">
          <img
            src={Logo}
            alt="CDP - Costa Rica"
            style={{ maxWidth: "160px" }}
          />
        </Link>
      </div>

      <div className="block lg:hidden">
        <button
          className="flex items-center px-3 py-2 border rounded text-gray-800 border-gray-400 hover:text-black hover:border-black"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className={`${
          isExpanded ? `block` : `hidden`
        } w-full block flex-grow lg:flex lg:items-center lg:w-auto nav-links transition duration-400 ease-in-out transform`}
      >
        <div className="lg:flex-grow md:items-center lg:items-center ml-10 pt-3 tracking-tight">
          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-lg hover:text-black mr-8">
            <Link to="/" activeClassName="underline">
              Inicio
            </Link>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-lg hover:text-black mr-8 ">
            <button
              activeClassName="underline"
              onMouseEnter={() => toggleLegal(!isLegal)}
            >
              La Firma
            </button>

            <div
              className={`${
                isLegal ? `hidden lg:block` : `block lg:hidden`
              } block lg:absolute lg:bg-gray-100 lg:border lg:border-gray-200 lg:rounded lg:p-5`}
              onMouseLeave={() => toggleLegal(!isLegal)}
            >
              <Link
                to="/nuestra-firma"
                className="block hover:underline ml-2 my-2 lg:my-0 lg:ml-0 text-base"
              >
                Nuestra Firma
              </Link>
              <Link
                to="/aldo-milano-sanchez"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Aldo Milano Sánchez
              </Link>
              <Link
                to="/maria-lourdes-echandi-gurdian"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                María Lourdes Echandi Gurdián
              </Link>
            </div>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-lg hover:text-black mr-8 ">
            <button
              activeClassName="underline"
              onMouseEnter={() => toggleShown(!isShown)}
            >
              Areas de Práctica
            </button>

            <div
              className={`${
                isShown ? `hidden lg:block` : `block lg:hidden`
              } block lg:absolute lg:bg-gray-100 lg:border lg:border-gray-200 lg:rounded lg:p-5 `}
              onMouseLeave={() => toggleShown(!isShown)}
            >
              <Link
                to="/derecho-constitucional"
                className="block hover:underline ml-2 my-2 lg:my-0 lg:ml-0 text-base"
              >
                Derecho Constitucional
              </Link>
              <Link
                to="/derecho-administrativo"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Derecho Administrativo
              </Link>
              <Link
                to="/derecho-de-la-competencia"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Derecho de la Competencia
              </Link>
              <Link
                to="/derecho-ambiental-y-urbanistico"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Derecho Ambiental y Urbanístico
              </Link>
              <Link
                to="/compras-del-estado"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Compras del Estado
              </Link>
              <Link
                to="/contencioso-administrativo"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Contencioso Administrativo
              </Link>
              <Link
                to="/concesion-de-obra-publica"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Concesión de Obra Pública
              </Link>
              <Link
                to="/derecho-farmaceutico"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Derecho Farmaceutico
              </Link>
              <Link
                to="/derecho-minero"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Derecho Minero
              </Link>
              <Link
                to="/derecho-municipal"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Derecho Municipal
              </Link>
              <Link
                to="/servicios-publicos"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Servicios Públicos
              </Link>
              <Link
                to="/resolucion-alterna-de-conflictos"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Resolución Alterna de Conflictos
              </Link>
              <Link
                to="/derecho-del-consumidor"
                className="block hover:underline ml-2  my-2 lg:my-0 lg:ml-0 text-base"
              >
                Derechos del Consumidor
              </Link>
            </div>
          </div>
          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-lg hover:text-black mr-8 ">
            <Link to="/publicaciones" activeClassName="underline">
              Publicaciones
            </Link>
          </div>
          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-lg hover:text-black mr-8 ">
            <Link to="/iuspublik" activeClassName="underline">
              IUSPUBLIK
            </Link>
          </div>
          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-lg hover:text-black mr-8 ">
            <Link to="/contacto" activeClassName="underline">
              Contacto
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation2
